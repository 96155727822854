<template>
  <DefaultLoader :loading="loading">
    <span v-show="!isEditing" class="mr-1">
      <slot>
        {{ dt }}
      </slot>
    </span>
    <div v-show="isEditing" class="input-group mb-3">
      <input
        :type="isText ? 'text' : 'number'"
        class="form-control form-control-sm"
        v-model="mutatedData"
        aria-label="Value"
        v-show="isEditing"
      />
      <button class="btn btn-outline-success" @click="Save">
        <i class="fas fa-check"></i>
      </button>
      <button class="btn btn-outline-danger" @click="isEditing = false">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <button
      v-show="!isEditing"
      class="btn btn-sm btn-neutral"
      @click="isEditing = true"
    >
      <i class="fas fa-edit"></i>
    </button>
  </DefaultLoader>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import { apiPut } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "EditableField",
  components: { DefaultLoader },
  emits: ["updated"],
  props: {
    dt: {
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    isText: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true,
    },
    parser: {
      type: Function,
      default: (data: string) => data,
    },
  },
  setup(props, context) {
    const loading = ref(false);
    const isEditing = ref(false);
    const mutatedData = ref();

    watchEffect(() => (mutatedData.value = props.dt));

    const Save = async () => {
      loading.value = true;
      try {
        await apiPut(props.url, {
          [props.field]: props.parser(mutatedData.value),
        });
        ShowSuccess();
        context.emit("updated", props.parser(mutatedData.value));
        isEditing.value = false;
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to save: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      isEditing,
      mutatedData,
      Save,
    };
  },
});
</script>

<style scoped></style>
