<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">
          Loading ...
        </span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">
          {{ dt.id }}
        </a>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.name }}
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.code }}
        </span>
      </td>
      <td style="min-width: 240px">
        <span class="font-weight-normal">
          <EditableField
            :dt="dt.we_buy"
            field="we_buy"
            :url="`/admin/fiats/${dt.id}`"
            :parser="(data) => Number(data)"
            @updated="$emit('updated', { field: 'we_buy', value: $event })"
          />
        </span>
      </td>
      <td style="min-width: 240px">
        <span class="font-weight-normal">
          <EditableField
            :dt="dt.we_sell"
            field="we_sell"
            :url="`/admin/fiats/${dt.id}`"
            :parser="(data) => Number(data)"
            @updated="$emit('updated', { field: 'we_sell', value: $event })"
          />
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.updated_at }}
        </span>
      </td>
      <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item text-dark">
              <span class="fas fa-eye mr-1"></span>
              Edit Currency
            </a>
            <a
              v-admin="100"
              class="dropdown-item text-danger"
              @click="
                RequestConfirmation(
                  'Do you really want to delete this currency?',
                  () => {}
                )
              "
            >
              <span class="fas fa-trash-alt mr-1"></span>
              Delete Currency
            </a>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import { inject, ref, defineComponent } from "vue";
import { apiPost } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";
import EditableField from "@/components/EditableField.vue";

export default defineComponent({
  name: "FiatCurrencyRow",
  components: { EditableField },
  emits: ["updated"],
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const ToggleAdmin = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/user-account/${
            props.dt.type === "BASIC_USER" ? "add-admin" : "remove-admin"
          }`,
          {
            userId: props.dt.id,
          }
        );
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.type =
          props.dt.type === "BASIC_USER" ? "ADMIN_USER" : "BASIC_USER";
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      ToggleAdmin,
    };
  },
});
</script>

<style scoped></style>
